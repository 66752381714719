.appFooter {
    background-color: #f0f2f5;
    padding: 20px 40px;
    text-align: center;
    border-top: 1px solid #e8e8e8;
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .footerLink {
    color: #1890ff;
    font-size: 16px;
    margin: 0 10px;
    transition: color 0.3s ease;
  }
  
  .footerLink:hover {
    color: #40a9ff;
    text-decoration: underline;
  }
  